
import { Component, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { Table as ElTable, TableColumn as ElTableColumn} from 'element-ui';
import VueSlider from 'vue-slider-component';
import { Splide, SplideSlide} from '@splidejs/vue-splide';
import LandingFooter from '@/components/Footer/LandingFooter.vue';

@Component({
  components: {
    Bar,
    ElTable,
    ElTableColumn,
    VueSlider,
    Splide,
    SplideSlide,
    LandingFooter,
  },
})
export default class Terms extends Vue {
  public mainLanding = true;
  public name = 'light-table';
  public currentPage = 1;

  public text = `
      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
      Nobis, sit autem aspernatur rem in nihil ducimus odit
      excepturi tempora architecto optio, cumque, vel magnam
      earum reiciendis maxime sint voluptates labore? Explicabo,
      iusto rem!
  `;
  get signLink() {
    if (window.location.host === 'apikey.nl') {
      return this.$router.currentRoute.params.promoCode ?
        `https://my.apikey.nl/register?promocode=${this.$router.currentRoute.params.promoCode}&client=true` : `https://my.apikey.nl/register?client=true`;
    } else {
      return this.$router.currentRoute.params.promoCode ?
          `https://${window.location.host}/register?promocode=${this.$router.currentRoute.params.promoCode}` : `https://${window.location.host}/register`;
    }
  }
  public beforeRouteEnter(to, from, next) {
    if (window.location.host !== 'apikey.nl') {
      next('/');
    } else {
      next();
    }
  }
  public mounted() {
    if (window.location.host === 'apikey.nl') {
      this.mainLanding = false;
    }
  }
}

